<template>
  <div style="width: 100%">
    <el-table border size="mini" :data="datas" style="width: 100%">
      <el-table-column
        fixed="left"
        prop="Member.Code"
        label="客户编号"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="Member.Name"
        fixed="left"
        label="姓名"
        min-width="120"
      >
      </el-table-column>
      <el-table-column prop="Member.Mobile" label="手机号" width="100">
      </el-table-column>
      <el-table-column prop="Member.Sex" label="性别" width="60">
      </el-table-column>
      <el-table-column prop="Member.Birthday" label="年龄" width="60">
        <template slot-scope="scope">
          <span>{{
            format.getAgeByDateString(
              format.dateFormat(scope.row.Member.Birthday)
            )
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="Answer1" label="问答1"> </el-table-column>
      <el-table-column prop="Answer2" label="问答2"></el-table-column>
      <el-table-column prop="Answer3" label="问答3"></el-table-column>
      <el-table-column prop="Answer4" label="问答4"></el-table-column>
      <el-table-column prop="Answer5" label="问答5"></el-table-column>
      <el-table-column prop="Answer6" label="问答6"></el-table-column>
      <el-table-column prop="Answer7" label="问答7"></el-table-column>
      <el-table-column prop="Answer8" label="问答8"></el-table-column>
      <el-table-column prop="Answer9" label="问答9" min-width="120">
        <template slot-scope="scope">
          {{ scope.row.Answer9_1 }}/{{ scope.row.Answer9_2 }}/{{
            scope.row.Answer9_3
          }}/{{ scope.row.Answer9_4 }}/{{ scope.row.Answer9_5 }}/{{
            scope.row.Answer9_6
          }}/{{ scope.row.Answer9_7 }}/{{ scope.row.Answer9_8 }}/{{
            scope.row.Answer9_9
          }}
        </template>
      </el-table-column>
      <el-table-column prop="Answer10" label="问答10"></el-table-column>
      <el-table-column prop="Answer11" label="问答11"></el-table-column>
      <el-table-column prop="InsertTime" label="自测时间"  width="120">
        <template slot-scope="scope">
          {{ format.dateTimeFormat(scope.row.InsertTime) }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :page-sizes="pageSizeList"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :current-page.sync="pageIndex"
    ></el-pagination>
  </div>
</template>
  <script>
import { getPageList, save, get } from "@/api/check/checkSelf.js";
import heartEditor from "./heartEditor.vue";
export default {
  components: { heartEditor },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      default: "",
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    filter: {
      handler(val) {
        this.pageIndex = 1;
        this.getData();
      },
    },
  },
  data() {
    return {
      datas: [],
      pageSize: 10,
      pageIndex: 1,
      total: 0,
      pageSizeList: [10, 20, 30, 40, 50],
      queryType: "IKDC",
    };
  },

  methods: {
    getData() {
      this.filter.pageIndex = this.pageIndex;
      this.filter.pageSize = this.pageSize;
      getPageList(this.filter).then((res) => {
        this.datas = res.Items;
        this.total = res.Total || res.Items.length;
      });
    },
    create() {
      this.$refs.heartEditor.handleCreate();
    },
    update(row) {
      let json = JSON.parse(JSON.stringify(row));
      this.$refs.heartEditor.handleEdit(json);
    },
    pageSizeChange(val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getData());
    },
    pageCurrentChange(val) {
      this.pageIndex = `${val}`;
      this.getData();
    },
  },
  mounted() {},
};
</script>
  <style lang='scss' scoped>
.status {
  align-self: flex-end;
  font-size: 12px;
  font-weight: bold;
  color: #ff862f;
}
.statusdesc {
  align-self: flex-end;
  font-size: 12px;
  color: #797878;
}
</style>